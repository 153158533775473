import React from 'react';

class Title extends React.Component {

    render() {
        return (
        <div id="title">
           SUPERNOVA
        </div>
        );
    }
}

export default Title;